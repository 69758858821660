<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.incertezas.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.incertezas.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Incerteza', 'Inserir')"
      :sem-filtro="!buscarPermissao('Incerteza', 'Listar')"
      @filtrar="filtrar"
      @abrirNovo="abrirNovo"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>

    <div class="d-flex mt-6">
      <botao-padrao
        v-if="podeExcluir && buscarPermissao('Incerteza', 'Deletar')"
        color="danger"
        outlined
        @click="excluirIncerteza"
      >
        <v-icon>$mdiTrashCanOutline</v-icon>
        {{ $t('geral.botoes.excluir') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      :mostrar-seletor="false"
      mostrar-seletor-check-box
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:seletor="{ slotProps }">
        <div>
          <v-simple-checkbox
            v-if="podeSelecionar(slotProps.data) && buscarPermissao('Incerteza', 'Excluir')"
            v-model="slotProps.data.isSelected"
            @input="
              selecionarIncerteza(slotProps.data, slotProps.data.isSelected)
            "
          />
        </div>
      </template>
      <template v-slot:revisao="{ slotProps }">
        <div class="alinhar-direita">
          {{ slotProps.data.revisao }}
        </div>
      </template>
      <template v-slot:revisaoPublicada="{ slotProps }">
        <div class="alinhar-direita">
          {{ slotProps.data.revisaoPublicada }}
        </div>
      </template>
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            v-if="buscarPermissao('Incerteza', 'Editar')"
            @click="abrirEditar(slotProps.data)"
          >
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Incerteza', 'Duplicar')"
            @click="duplicarIncerteza(slotProps.data)"
          >
            {{ $t('geral.botoes.duplicar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Incerteza', 'Visualizar')"
            @click="abrirVisualizarRevisoes(slotProps.data)"
          >
            {{ $t('geral.botoes.visualizar_revisoes') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>

<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import IncertezasService from '@common/services/cadastros/IncertezasService';
import Filtro from './filtro.vue';
import helpers from '@common/utils/helpers';

export default {
  components: { CabecalhoPagina, Filtro },

  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        quantidadeItens: 0,
        quantidadePaginas: 1,
        paginaAtual: 1,
        porPagina: 8,
        colunas: [
          {
            text: this.$t('modulos.incertezas.tabela.codigo'),
            sortable: true,
            value: 'codigo',
          },
          {
            text: this.$t('modulos.incertezas.tabela.nome'),
            sortable: true,
            value: 'nome',
          },
          {
            text: this.$t('modulos.incertezas.tabela.faixa'),
            sortable: true,
            value: 'faixa',
          },
          {
            text: this.$t('modulos.incertezas.tabela.revisao_publ'),
            sortable: true,
            value: 'revisaoPublicada',
          },
          {
            text: this.$t('modulos.incertezas.tabela.publicada_em'),
            sortable: true,
            value: 'dataPublicacao',
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, true); // true = hora
            },
          },
        ],
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Incerteza', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.listarRegistros();

    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.incertezas.titulos.listagem')
    );
  },

  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    podeSelecionar: function (item) {
      return item.revisaoPublicada === null;
    },

    selecionarIncerteza: function (item, selecionado) {
      if (!selecionado) {
        this.tabela.selecionados.splice(
          this.tabela.selecionados.indexOf(
            this.tabela.selecionados.find((el) => el.id == item.id)
          ),
          1
        );
      } else {
        this.tabela.selecionados.push(item);
      }
    },

    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },

    abrirEditar: function (item) {
      if (item.revisao != item.revisaoPublicada) {
        this.$router.push({
          name: 'incertezas-editar',
          params: { id: item.id },
        });
        return;
      }
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.incertezas.alertas.incerteza')
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        IncertezasService.criarRevisao(item.id)
          .then(() => {
            this.$router.push({
              name: 'incertezas-editar',
              params: { id: item.id },
            });
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },

    abrirVisualizarRevisoes: function (item) {
      this.$router.push({
        name: 'incertezas-revisoes',
        params: { id: item.id },
      });
    },

    duplicarIncerteza: function (item) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      IncertezasService.duplicar(item.id)
        .then((response) => {
          this.toastSucesso(
            this.$t(`modulos.incertezas.duplicar_sucesso`, {
              INC: response.data,
            })
          );

          this.listarRegistros();
        })
        .catch((error) => {
          this.toastErro(
            this.$t(`geral.erros.${error.response.data.errors[0]}`)
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        Page: paginaAtual,
        AmountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };

      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      IncertezasService.listar(parametros)
        .then((res) => {
          const dados = [];
          for (const dado of res.data.items) {
            dados.push({
              ...dado,
              faixa: helpers.formatarDescricao(`(${dado.amplitudeMinima} a ${dado.amplitudeMaxima}) ${dado.unidadeMedida.simbolo}`),
            });
          }

          this.tabela.dados = dados;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    excluirIncerteza: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        let promises = [];

        this.tabela.selecionados.forEach((incerteza) => {
          promises.push(IncertezasService.excluirIncerteza(incerteza.id));
        });

        Promise.all(promises)
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(
              this.$t('modulos.incertezas.incerteza_excluida_com_sucesso')
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },

    abrirNovo: function () {
      this.$router.push({ name: 'incertezas-novo' });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
<style lang="scss">
.alinhar-direita {
  display: flex;
  justify-content: right;
}
</style>
