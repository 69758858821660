<template>
  <v-row>
    <input-text
      v-model="filtro.Codigo"
      class="col-6"
      :label="$t('modulos.incertezas.filtro.codigo')"
      :placeholder="$t('modulos.incertezas.filtro.codigo')"
      em-filtro
    />
    <input-text
      v-model="filtro.Nome"
      class="col-6"
      :label="$t('modulos.incertezas.filtro.nome')"
      :placeholder="$t('modulos.incertezas.filtro.nome')"
      em-filtro
    />
    <input-text
      v-model="filtro.Faixa"
      class="col-12"
      :label="$t('modulos.incertezas.filtro.faixa')"
      :placeholder="$t('modulos.incertezas.filtro.faixa')"
      em-filtro
    />
  </v-row>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line
    filtro: {},
  },
}
</script>
